import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Hero";
import About from "./components/pages/About";
import Products from "./components/pages/Products";
import Process from "./components/pages/Process";
import Testimonials from "./components/pages/Testimonials";
import Order from "./components/pages/Order";
import SuccessPage from "./components/pages/SuccessPage";
import styles from "./App.module.css";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Hero from "./components/pages/Hero";
import PackagingAndDelivery from "./components/pages/PackagingAndDelivery";
import Terms from "./components/pages/Terms";
import Privacy from "./components/pages/Privacy";

function App() {
  return (
    <Router>
      <div className={styles.app}>
        <Header />
        <main className={styles.main}>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <About />
                  <Products />
                  <Process />
                  <PackagingAndDelivery />
                  <Testimonials />
                  <Order />
                </>
              }
            />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/termeni-si-conditii" element={<Terms />} />
            <Route
              path="/politica-de-confidentialitate"
              element={<Privacy />}
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
