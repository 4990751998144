import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation and useNavigate
import styles from "./Header.module.css";
import logo from "../../assets/logo.webp";
import Button from "../common/Button";
import { scrollToCommandaAndFocus } from "../../utils/focusUtilts";

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (sectionId: string) => {
    if (location.pathname !== "/") {
      navigate("/");
      // Wait for navigation to complete before scrolling
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
          setIsMenuOpen(false);
        }
      }, 100);
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        setIsMenuOpen(false);
      }
    }
  };

  const handleCommandaClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        scrollToCommandaAndFocus();
      }, 100);
    } else {
      scrollToCommandaAndFocus();
    }
    setIsMenuOpen(false);
  };

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (location.pathname === "/") {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setIsMenuOpen(false);
  };

  return (
    <header className={`${styles.header} ${isScrolled ? styles.scrolled : ""}`}>
      <div className={styles.container}>
        <Link to="/" className={styles.logoLink} onClick={handleLogoClick}>
          <img src={logo} alt="ChinArt Logo" className={styles.logo} />
          <span className={styles.logoText}>ChinArt</span>
        </Link>
        <div className={styles.rightContent}>
          <nav className={`${styles.nav} ${isMenuOpen ? styles.open : ""}`}>
            <ul className={styles.navList}>
              <li>
                <a onClick={() => scrollToSection("despre")}>Despre</a>
              </li>
              <li>
                <a onClick={() => scrollToSection("produse")}>Produse</a>
              </li>
              <li>
                <a onClick={() => scrollToSection("proces")}>Procesul Nostru</a>
              </li>
              <li>
                <a onClick={() => scrollToSection("testimoniale")}>
                  Testimoniale
                </a>
              </li>
            </ul>
          </nav>
          <Button
            variant="primary"
            size="small"
            onClick={handleCommandaClick}
            className={styles.comandaButton}
          >
            Comandă
          </Button>
          <button
            className={styles.menuToggle}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
