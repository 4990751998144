import React from "react";
import styles from "./Privacy.module.css";

const Privacy: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Politica de Confidențialitate</h1>
      <div className={styles.pdfContainer}>
        <object
          data="/assets/privacy-policy.ro.pdf"
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>
            Browserul dumneavoastră nu poate afișa PDF-ul încorporat.{" "}
            <a
              href="/assets/privacy-policy.ro.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Descărcați PDF-ul
            </a>{" "}
            pentru a-l vizualiza.
          </p>
        </object>
      </div>
    </div>
  );
};

export default Privacy;
