import React, { useState } from "react";
import styles from "./ImageSlider.module.css";

interface ImageSliderProps {
  images: string[];
  alt: string;
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, alt }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className={styles.slider}>
      <img src={images[currentIndex]} alt={alt} className={styles.image} />
      {images.length > 1 && (
        <>
          <button
            className={`${styles.arrow} ${styles.leftArrow}`}
            onClick={prevSlide}
          >
            &#10094;
          </button>
          <button
            className={`${styles.arrow} ${styles.rightArrow}`}
            onClick={nextSlide}
          >
            &#10095;
          </button>
          <div className={styles.dots}>
            {images.map((_, index) => (
              <span
                key={index}
                className={`${styles.dot} ${
                  index === currentIndex ? styles.activeDot : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentIndex(index);
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ImageSlider;
